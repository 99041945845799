<template>
  <component
    :is="args.is"
    v-bind="bindings"
    v-analytics.click="{
      gtm: ['landing', 'participation', 'participation_button_click'],
      amplitude
    }"
    :class="classes"
  >
    <slot />

    <span :class="$style.icon" v-if="arrow">
      <icon-arrow />
    </span>
  </component>
</template>

<script>
import UiLink from '~/components/ui/link/index.vue'
import { useAccountStore } from '~/stores/account'
import IconArrow from '../icon/arrow.vue'

const PATH_TO = '/tasks'

export default {
  name: 'CommonButton',
  components: {
    UiLink,
    IconArrow
  },
  props: {
    disabled: {
      type: Boolean,
      default: false
    },
    color: {
      type: String,
      default: 'default'
    },
    size: {
      type: String,
      default: 'large'
    },
    arrow: {
      type: Boolean,
      default: true
    },
    amplitude: {
      type: Array,
      default: () => []
    }
  },
  computed: {
    classes() {
      return [
        this.$style.button,
        this.$style[this.color],
        this.$style[this.size],
        { [this.$style.disabled]: this.disabled }
      ]
    }
  },
  setup() {
    const { $sdk } = useNuxtApp()
    const accountStore = useAccountStore()
    const { isAuthorized } = storeToRefs(accountStore)

    const login = () =>
      $sdk.module('auth').then(({ login }) => login({ redirect: PATH_TO }))

    const args = computed(() => {
      const result = {}

      if (isAuthorized.value) {
        result.is = UiLink
        result.to = PATH_TO
      } else {
        result.is = 'button'
        result.type = 'button'
        result.onClick = () =>
          login().catch(err => {
            console.error(err)
          })
      }

      return result
    })

    const bindings = computed(() => {
      const { is, ...result } = args.value

      return result
    })

    return { args, bindings }
  }
}
</script>

<style lang="scss" module>
.button {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  transition: all 0.2s;
  user-select: none;
  transform: skew(-15deg);
  gap: 1.8em;
  font-style: italic;

  @include down(md) {
    gap: 0.9em;
  }

  > span:not(.icon) {
    transform: skew(15deg);
  }

  .icon {
    width: 3.4em;
    height: 2.5em;
    transform: skew(15deg);

    @include down(md) {
      width: 1.5em;
      height: 1.1em;
    }

    @include down(md) {
      width: 3.4em;
      height: 2.5em;
      margin-left: 0.625em;
    }

    svg {
      display: block;
      width: 100%;
      height: 100%;
    }
  }

  &.disabled {
    pointer-events: none;
    opacity: 0.5;
  }

  &.default {
    background: #bdee00;
    color: #000;

    &:hover {
      background: lighten(#bdee00, 5%);
    }
  }

  &.accent {
    background: #fff;
    color: #000;

    &:hover {
      background: #00c7b1;
    }
  }

  &.large {
    height: 8em;
    padding: 0 6em;
    border-radius: 0.8em;

    @include down(md) {
      height: 3.5em;
      padding: 0 2.8em;
      border-radius: 0.35em;
    }

    @include down(md) {
      height: 8em;
      padding: 0 6em;
      border-radius: 0.8em;
    }

    > span:not(.icon) {
      font-weight: 800;
      font-size: 3.2em;
      line-height: 102%;
      text-transform: uppercase;

      @include down(md) {
        font-size: 1.4em;
      }

      @include down(md) {
        font-size: 3.2em;
      }
    }
  }

  &.small {
    height: 5.6em;
    width: 16em;
    border-radius: 0.539167em;

    @include down(md) {
      height: 3.6em;
      width: 10em;
      border-radius: 0.34em;
    }

    > span:not(.icon) {
      font-style: normal;
      font-weight: 700;
      font-size: 1.96em;
      line-height: 110%;

      @include down(md) {
        font-size: 1.2em;
      }
    }
  }
}
</style>
