<template>
  <footer :class="$style.footer">
    <div :class="$style.wrap">
      <div :class="$style.left">
        <div
          :class="[$style.image, $style[system]]"
          v-for="system in paymentSystems"
          :key="system.name"
        >
          <img :src="system.image" loading="lazy" alt="" />
        </div>
      </div>
      <div :class="$style.center" v-if="$route.query.webview === undefined">
        <a
          :class="[$style.image, $style[store.name]]"
          v-for="(store, i) in stores"
          :key="i + '_store'"
          :href="store.href"
          target="_blank"
          v-analytics.click="{
            gtm: store.gtm,
            amplitude: [
              'button_click',
              {
                button_name: store.amplitude
              }
            ]
          }"
        >
          <img :src="store.image" alt="" />
        </a>
      </div>
      <div :class="$style.right">
        <div :class="[$style.image, $style.eighteen]">
          <img :src="Socials18" alt="" loading="lazy" />
        </div>
        <template v-for="(social, i) in socials" :key="i + '_social'">
          <a
            :class="[$style.image, $style[social.name]]"
            v-if="!social.hide"
            :href="social.href"
            target="_blank"
            v-analytics.click="{
              gtm: social.gtm,
              amplitude: [
                'button_click',
                {
                  button_name: social.amplitude
                }
              ]
            }"
          >
            <img :src="social.image" alt="" />
          </a>
        </template>
      </div>
    </div>
  </footer>
</template>

<script setup>
import paymentSystemsIomoney from './images/payment-systems/iomoney.svg?url'
import paymentSystemsMastercard from './images/payment-systems/mastercard.svg?url'
import paymentSystemsMir from './images/payment-systems/mir.svg?url'
import paymentSystemsSberpay from './images/payment-systems/sberpay.svg?url'
import paymentSystemsVisa from './images/payment-systems/visa.svg?url'

import Socials18 from './images/socials/18.svg?url'
import SocialsTelegram from './images/socials/telegram.svg?url'
import SocialsVk from './images/socials/vk.svg?url'

import StoresAndroid from './images/stores/android.svg?url'
import StoresAppgallery from './images/stores/appgallery.svg?url'
import StoresAppstore from './images/stores/appstore.svg?url'
import StoresGalaxystore from './images/stores/galaxystore.svg?url'
import StoresXiaomi from './images/stores/xiaomi.svg?url'
// import StoresRustore from './images/stores/rustore.svg?url'

const route = useRoute()
const paymentSystems = [
  { name: 'visa', image: paymentSystemsVisa },
  { name: 'mastercard', image: paymentSystemsMastercard },
  { name: 'sberpay', image: paymentSystemsSberpay },
  { name: 'mir', image: paymentSystemsMir },
  { name: 'iomoney', image: paymentSystemsIomoney }
]
const stores = [
  {
    name: 'android',
    image: StoresAndroid,
    href: 'https://app.appsflyer.com/ru.paribet-Android?pid=landings&c=apk&af_r=https://origin.pb06e2-resources.com/webStaticPB/download/mobile/android/pari-latest.apk&af_click_lookback=7d',
    gtm: ['landing', 'download', 'android_download_click'],
    amplitude: 'download_android'
  },
  {
    name: 'appstore',
    image: StoresAppstore,
    href: 'https://l.pari.ru/mEiY/landbutton',
    gtm: ['landing', 'download', 'ios_download_click'],
    amplitude: 'download_ios'
  },

  {
    name: 'appgallery',
    image: StoresAppgallery,
    href: 'https://l.pari.ru/mEiY/landbutton',
    gtm: ['landing', 'download', 'huawei_download_click'],
    amplitude: 'download_huawei'
  },
  {
    name: 'galaxystore',
    image: StoresGalaxystore,
    href: 'https://l.pari.ru/mEiY/landbutton',
    gtm: ['landing', 'download', 'samsung_download_click'],
    amplitude: 'download_samsung'
  },
  {
    name: 'xiaomi',
    image: StoresXiaomi,
    href: 'https://l.pari.ru/mEiY/landbutton',
    gtm: ['landing', 'download', 'xiaomi_download_click'],
    amplitude: 'download_xiaomi'
  }
  // {
  //   name: 'rustore',
  //   image: StoresRustore,
  //   href: 'https://l.pari.ru/mEiY/landbutton'
  // }
]
const socials = computed(() => [
  {
    name: 'telegram',
    href: 'https://t.me/bc_pari',
    image: SocialsTelegram,
    gtm: ['landing', 'social', 'telegram_account'],
    amplitude: 'social_telegram'
  },
  {
    name: 'vk',
    href: 'https://vk.com/bc_pari',
    image: SocialsVk,
    gtm: ['landing', 'social', 'vkontakte_account'],
    amplitude: 'social_vkontakte',
    hide: route.query.platform === 'vk'
  }
])
</script>

<style module lang="scss">
.footer {
  background-color: #1d212b;
  display: flex;
  height: 8em;
  @include down(md) {
    height: auto;
    padding: 4.4em 2em 4em;
  }
}

.wrap {
  max-width: 182em;
  width: 100%;
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: space-between;
  @include down(md) {
    flex-direction: column;
    gap: 2em;
  }
}

.left {
  display: flex;
  align-items: center;
  gap: 3em;
  height: 2.5em;

  @include down(md) {
    justify-content: space-between;
    width: 100%;
  }

  .image {
    img {
      height: 100%;
      width: 100%;
    }
  }

  .visa {
    width: 4.7em;
    height: 1.6em;
  }

  .mastercard {
    width: 4.2em;
    height: 2.5em;
  }

  .mir {
    width: 5.7em;
    height: 1.6em;
    @include down(md) {
      display: none;
    }
  }

  .qiwi {
    width: 7.1em;
    height: 2.5em;
    @include down(md) {
      display: none;
    }
  }

  .iomoney {
    width: 10.1em;
    height: 2em;
  }

  .sberpay {
    display: none;
    @include down(md) {
      display: block;
      width: 5.5em;
      height: 2.5em;
    }
  }
}

.center {
  display: flex;
  align-items: center;
  gap: 3em;
  @include down(md) {
    gap: 1em;
    flex-wrap: wrap;
  }

  .image {
    width: 14em;
    @include down(md) {
      width: calc(50% - 0.5em);
    }

    img {
      width: 100%;
      height: 100%;
    }
  }

  .rustore {
    display: none;
    @include down(md) {
      display: block;
    }
  }
}

.right {
  display: flex;
  align-items: center;
  gap: 4em;
  @include down(md) {
    gap: 3.5em;
    width: 100%;
  }

  .image {
    img {
      width: 100%;
      height: 100%;
    }
  }

  .eighteen {
    width: 3em;
    height: 3em;
    @include down(md) {
      width: 2.4em;
      height: 2.4em;
      margin-right: auto;
    }
  }

  .telegram {
    width: 2.4em;
    height: 2em;
    @include down(md) {
      width: 2.2em;
      height: 1.8em;
    }
  }

  .vk {
    width: 3.2em;
    height: 2em;
    @include down(md) {
      width: 3em;
      height: 1.8em;
    }
  }

  .twitter {
    width: 2.5em;
    height: 2em;
    @include down(md) {
      display: none;
    }
  }
}
</style>
